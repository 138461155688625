const artData = [
  {
    id: 0,
    title: "Portrait: Alex Turner",
    date: "3/20/2019",
    medium: "oil paint on paper",
    type: "Paint",
    recencyRank: 5,
    favoriteRank: 3,
  },
  {
    id: 1,
    title: "Self Portrait",
    date: "4/10/2019",
    medium: "oil paint on canvas",
    type: "Paint",
    recencyRank: 4,
    favoriteRank: 2,
  },
  {
    id: 2,
    title: "Model Portrait Study",
    date: "1/31/2020",
    medium: "oil paint on canvas",
    type: "Paint",
    recencyRank: 3,
    favoriteRank: 5,
  },
  {
    id: 3,
    title: "Tag Yourself",
    date: "2/2/2020",
    medium: "oil paint on canvas",
    type: "Paint",
    recencyRank: 2,
    favoriteRank: 1,
  },
  {
    id: 4,
    title: "Untitled",
    date: "4/21/2020",
    medium: "oil paint on paper",
    type: "Paint",
    recencyRank: 1,
    favoriteRank: 4,
  },
];

export default artData;
