import React from "react";
import ArtEntry from "../ArtEntry/ArtEntry";
import ArtDisplay from "../ArtDisplay/ArtDisplay";
import "./Art.css";
import { SortArtState } from "../../utilities/constants.ts";
import { FilterArtState } from "../../utilities/constants.ts";
import artData from "../../data/artData.js";
import loadingSpinner from "../../media/icons/loader-spinner.svg";

class Art extends React.Component {
  constructor() {
    super();
    this.state = {
      listedArts: [],
      sortParams: [false, false],
      filterParams: [false, false],
      thumbnails: [
        require("../../media/art/thumbnails/paint-0.jpg"),
        require("../../media/art/thumbnails/paint-1.jpg"),
        require("../../media/art/thumbnails/paint-2.jpg"),
        require("../../media/art/thumbnails/paint-3.jpg"),
        require("../../media/art/thumbnails/paint-4.jpg"),
      ],
      images: [
        require("../../media/art/source/paint-0.jpg"),
        require("../../media/art/source/paint-1.jpg"),
        require("../../media/art/source/paint-2.jpg"),
        require("../../media/art/source/paint-3.jpg"),
        require("../../media/art/source/paint-4.jpg"),
      ],
      thumbLoadStatus: [],
      thumbsLoaded: false,
      sourceLoadStatus: [],
      sourceDisplayStatus: [false, false, false, false, false],
      displayArt: false,
      artId: -1,
      artTitle: "",
      artDate: "",
      artMedium: "",
    };
    this.updateSortParams = this.updateSortParams.bind(this);
    this.updateFilterParams = this.updateFilterParams.bind(this);
    this.sortArts = this.sortArts.bind(this);
    this.getFilteredArts = this.getFilteredArts.bind(this);
    this.displayArt = this.displayArt.bind(this);
    this.hideArtDisplay = this.hideArtDisplay.bind(this);
    this.thumbLoaded = this.thumbLoaded.bind(this);
    this.sourceLoaded = this.sourceLoaded.bind(this);
    this.renderLoadSpinner = this.renderLoadSpinner.bind(this);
  }

  componentDidMount() {
    // Load in art entries
    let arts = artData.map((art) => (
      <ArtEntry
        key={art.id}
        art={art}
        thumbnail={this.state.thumbnails[art.id]}
        displayArt={this.displayArt}
        thumbLoaded={this.thumbLoaded}
      />
    ));
    this.allArts = [...arts];

    // // Set initial display statuses for art sources
    // let initSourceDisplayStatus = artData.map(() => {
    //   return true;
    // });
    // console.log(initSourceDisplayStatus);

    // Load in all art source images
    // this.sourceImages = artData.map((art) => (
    //   <ArtDisplay
    //     key={art.id}
    //     id={art.id}
    //     src={this.state.images[art.id]}
    //     alt={art.title + " Art Piece"}
    //     sourceLoaded={this.sourceLoaded}
    //     displayStatus={this.state.sourceDisplayStatus}
    //   />
    // ));

    // Set load status for images
    let initStatus = [];
    let i;
    for (i = 0; i < arts.length; i++) initStatus.push(0);

    // Set states
    this.setState(
      {
        listedArts: arts,
        thumbLoadStatus: initStatus,
        //sourceDisplayStatus: initSourceDisplayStatus,
      },
      () => {
        // Set initial sort to Recent art
        this.updateSortParams(SortArtState.RECENT);
      }
    );
  }

  updateSortParams(i) {
    // Don't update if user clicked the already toggled button
    if (this.state.sortParams[i]) return;

    this.setState(
      (prevState) => {
        const newSortParams = prevState.sortParams.map((param, j) => {
          if (j === i) {
            return !param;
          } else {
            return false;
          }
        });

        return {
          sortParams: newSortParams,
        };
      },
      () => {
        this.sortArts();
      }
    );
  }

  updateFilterParams(i) {
    this.setState((prevState) => {
      const newFilterParams = prevState.filterParams.map((param, j) => {
        if (j === i) {
          return !param;
        } else {
          return false;
        }
      });

      return {
        filterParams: newFilterParams,
      };
    });
  }

  sortArts() {
    if (this.state.sortParams[SortArtState.RECENT]) {
      this.setState((prevState) => {
        let newListedArts = prevState.listedArts.sort((a, b) =>
          a.props.art.recencyRank > b.props.art.recencyRank ? 1 : -1
        );
        return { listedArts: newListedArts };
      });
    } else if (this.state.sortParams[SortArtState.FAVORITE]) {
      this.setState((prevState) => {
        let newListedArts = prevState.listedArts.sort((a, b) =>
          a.props.art.favoriteRank > b.props.art.favoriteRank ? 1 : -1
        );
        return { listedArts: newListedArts };
      });
    } else {
      this.setState((prevState) => {
        const allArtsCopy = [...this.allArts];
        return { listedArts: allArtsCopy };
      });
    }
  }

  getFilteredArts() {
    if (!this.state.filterParams.includes(true)) return this.state.listedArts;

    let filteredString = "";
    if (this.state.filterParams[FilterArtState.PAINT]) filteredString = "Paint";
    else if (this.state.filterParams[FilterArtState.PIXEL])
      filteredString = "Pixel";

    let filteredArts = [];
    this.state.listedArts.forEach((element) => {
      if (element.props.art.type === filteredString) filteredArts.push(element);
    });
    return filteredArts;
  }

  displayArt(art) {
    if (art != null) {
      this.setState(
        (prevState) => {
          // If art to display is same as last, don't bother updating all states
          if (prevState.artId === art.id) return { displayArt: true };

          // Hide last image and show new image
          let newStatus = [...prevState.sourceDisplayStatus];
          if (prevState.artId > -1) newStatus[prevState.artId] = false;
          newStatus[art.id] = true;

          // If art to display is different from last, update all states and set
          return {
            displayArt: true,
            artId: art.id,
            artTitle: art.title,
            artDate: art.date,
            artMedium: art.medium,
            sourceDisplayStatus: newStatus, // Update display status for source images
          };
        },
        () => {
          console.log(this.state.sourceDisplayStatus);
        }
      );
    }

    // Prevent scrolling
    document.body.classList.add("prevent-scrolling");
  }

  hideArtDisplay(e) {
    // Ignore clicks elsewhere
    if (e.target !== e.currentTarget) return;
    this.setState({ displayArt: false });
    // Resume scrolling
    document.body.classList.remove("prevent-scrolling");
  }

  thumbLoaded(i) {
    this.setState((prevState) => {
      // Set new ID as loaded
      let newStatus = [...prevState.thumbLoadStatus];
      newStatus[i] = 1;

      // Check if all IDs are loaded
      let allLoaded = false;
      if (!newStatus.includes(0)) allLoaded = true;

      return { thumbLoadStatus: newStatus, thumbsLoaded: allLoaded };
    });
  }

  sourceLoaded(i) {
    console.log(i);
    this.setState((prevState) => {
      // Set new ID as loaded
      let newStatus = [...prevState.sourceLoadStatus];
      newStatus[i] = 1;

      return { sourceLoadStatus: newStatus };
    });
  }

  renderLoadSpinner(id) {
    if (
      (!this.state.thumbsLoaded && id === 0) ||
      (!this.state.sourceLoadStatus[this.state.artId] && id === 1)
    )
      return (
        <img
          className="loader-spinner"
          src={loadingSpinner}
          alt="Loading Spinner"
        />
      );
    return null;
  }

  render() {
    // Get filter styles
    const btnSelected = " btn-selected";
    let recentBtnClass = this.state.sortParams[SortArtState.RECENT]
      ? btnSelected
      : "";
    let favoriteBtnClass = this.state.sortParams[SortArtState.FAVORITE]
      ? btnSelected
      : "";
    let paintBtnClass = this.state.filterParams[FilterArtState.PAINT]
      ? btnSelected
      : "";
    let pixelBtnClass = this.state.filterParams[FilterArtState.PIXEL]
      ? btnSelected
      : "";

    // Determine whether entries are ready to show
    let entriesDisplay = { display: "none" };
    if (this.state.thumbsLoaded) entriesDisplay = {};

    return (
      <section className="Art page site-content">
        <div className="filter-container" id="art-filter">
          <div className="options-wrapper">
            <span>Sort by:</span>
            <a
              className={"btn btn-sm" + recentBtnClass}
              onClick={() => {
                this.updateSortParams(SortArtState.RECENT);
              }}
            >
              Recent
            </a>

            <a
              className={"btn btn-sm" + favoriteBtnClass}
              onClick={() => {
                this.updateSortParams(SortArtState.FAVORITE);
              }}
            >
              Favorites
            </a>
          </div>
          <div className="options-wrapper">
            <span>Filter by:</span>
            <a
              className={"btn btn-sm" + paintBtnClass}
              onClick={() => {
                this.updateFilterParams(FilterArtState.PAINT);
              }}
            >
              Painting
            </a>
            <a
              className={"btn btn-sm" + pixelBtnClass}
              onClick={() => {
                this.updateFilterParams(FilterArtState.PIXEL);
              }}
            >
              Pixel Art
            </a>
          </div>
        </div>
        {this.renderLoadSpinner(0)}
        <div className="entries-wrapper" style={entriesDisplay}>
          {this.getFilteredArts()}
        </div>
        <div
          className="dark-display-screen"
          style={
            this.state.displayArt ? { display: "block" } : { display: "none" }
          }
          onClick={this.hideArtDisplay}
        >
          <i className="fas fa-times exit-btn" onClick={this.hideArtDisplay} />
          {this.renderLoadSpinner(1)}
          <div className="art-display-container" onClick={this.hideArtDisplay}>
            <ArtDisplay
              id={artData[0].id}
              src={this.state.images[artData[0].id]}
              alt={artData[0].title + " Art Piece"}
              sourceLoaded={this.sourceLoaded}
              displayStatus={this.state.sourceDisplayStatus}
            />
            <ArtDisplay
              id={artData[1].id}
              src={this.state.images[artData[1].id]}
              alt={artData[1].title + " Art Piece"}
              sourceLoaded={this.sourceLoaded}
              displayStatus={this.state.sourceDisplayStatus}
            />
            <ArtDisplay
              id={artData[2].id}
              src={this.state.images[artData[2].id]}
              alt={artData[2].title + " Art Piece"}
              sourceLoaded={this.sourceLoaded}
              displayStatus={this.state.sourceDisplayStatus}
            />
            <ArtDisplay
              id={artData[3].id}
              src={this.state.images[artData[3].id]}
              alt={artData[3].title + " Art Piece"}
              sourceLoaded={this.sourceLoaded}
              displayStatus={this.state.sourceDisplayStatus}
            />
            <ArtDisplay
              id={artData[4].id}
              src={this.state.images[artData[4].id]}
              alt={artData[4].title + " Art Piece"}
              sourceLoaded={this.sourceLoaded}
              displayStatus={this.state.sourceDisplayStatus}
            />
            <div className="art-plaque">
              <h2>
                <b>
                  <i>{this.state.artTitle + ", "}</i>
                </b>
                {this.state.artDate}
              </h2>
              <h3>{this.state.artMedium}</h3>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Art;
