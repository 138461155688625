export enum PageState {
  HOME = "home",
  ABOUT = "about",
  WORK = "work",
  ART = "art",
}

export enum SortWorkState {
  RECENT,
  BEST,
  FAVORITE,
}

export enum FilterWorkState {
  PROGRAMMER,
  DESIGNER,
}

export enum SortArtState {
  RECENT,
  FAVORITE,
}

export enum FilterArtState {
  PAINT,
  PIXEL,
}
