const workData = [
  {
    id: 0,
    name: "Merc Manager",
    date: "Apr 2019",
    roles: ["Programmer", "Designer"],
    description:
      "Merc Manager is a strategy game made in 72 hours for the Ludum Dare 44 Jam with my friend Marcus Dubreuil. My work was creating all art assets, designing and implementing the UI, and programming the playable characters that interface with the AI system.",
    language: "JavaScript",
    technologies: "PixiJS",
    url: "http://ld44.marcusman.com/",
    recencyRank: 5,
    bestRank: 3,
    favoriteRank: 1,
  },
  {
    id: 1,
    name: "2D Hockey",
    date: "Fall 2018",
    roles: ["Programmer"],
    description:
      "For this term project, my job was to implement an impulse physics engine for our hockey video game. This involved acting as temporary leader of a large team, as well as presenting a 40-minute technical lecture on physics engines.",
    language: "C++",
    technologies: "SDL",
    url: "",
    recencyRank: 6,
    bestRank: 1,
    favoriteRank: 3,
  },
  {
    id: 2,
    name: "FlipFolder Website",
    date: "May 2020",
    roles: ["Programmer", "Designer"],
    description:
      "I was commissioned to redesign the landing page for the startup FlipFolderApp. Working closely with the lead programmer and CEO, I rebuilt the page to better represent the company's values and improve the user experience. I continue to help maintain the overall site.",
    language: "HTML, CSS, JavaScript",
    technologies: "Pug, Stylus, Bootstrap, JQuery",
    url: "https://flipfolderapp.com/",
    recencyRank: 3,
    bestRank: 6,
    favoriteRank: 2,
  },
  {
    id: 3,
    name: "NotesRemote Website",
    date: "Aug 2020",
    roles: ["Programmer", "Designer"],
    description:
      "I was commissioned to design the landing page for a brand new software by Bandsavvy LLC. Alongside frequent communication with the client, I fully designed and developed the page with a focus on brand marketability and website responsiveness. For this project I also designed the logo and featured illustration.",
    language: "HTML, CSS, JavaScript",
    technologies: "Pug, Stylus",
    url:
      "https://xd.adobe.com/view/6b2dd0ca-8997-4a79-b3f7-7272d986ce0d-3e7e/?fullscreen",
    recencyRank: 2,
    bestRank: 5,
    favoriteRank: 5,
  },
  {
    id: 4,
    name: "Portfolio Website",
    date: "Summer 2020",
    roles: ["Programmer", "Designer"],
    description:
      "As a passion project, I wanted to create a place to showcase my web design and development skills, as well as my personality. I also wanted the opportunity to build fluency in React, and have really grown to love it.",
    language: "HTML, CSS, JavaScript",
    technologies: "React, Sass",
    url: "https://ethandefrank.com/",
    recencyRank: 1,
    bestRank: 4,
    favoriteRank: 6,
  },
  {
    id: 5,
    name: "Undergraduate Research",
    date: "2019",
    roles: ["Programmer"],
    description:
      "To diversify my academic experiences, I joined a research project that sought to answer the question: 'Can games substitute for driving simulators?' If we can correlate crash risk and cognitive distraction in gaming with the same accuracy as a high-fidelity driving simulator, we can make it more affordable and accessible to conduct research on distracted driving.",
    language: "JavaScript",
    technologies: "Node.js",
    url:
      "https://drive.google.com/drive/folders/15Uj2Aijfi5e9jzR0ajDAP_qGcGYUOqEe?usp=sharing",
    recencyRank: 4,
    bestRank: 2,
    favoriteRank: 4,
  },
];

export default workData;
