import React from "react";
import ProjectEntry from "../ProjectEntry/ProjectEntry";
import "./Work.css";
import { SortWorkState } from "../../utilities/constants.ts";
import { FilterWorkState } from "../../utilities/constants.ts";
import workData from "../../data/workData.js";
import loadingSpinner from "../../media/icons/loader-spinner.svg";

class Work extends React.Component {
  constructor() {
    super();
    this.state = {
      listedWorks: [],
      sortParams: [false, false, false],
      filterParams: [false, false],
      thumbnails: [
        require("../../media/thumbnails/thumbnail-0.png"),
        require("../../media/thumbnails/thumbnail-1.png"),
        require("../../media/thumbnails/thumbnail-2.png"),
        require("../../media/thumbnails/thumbnail-3.png"),
        require("../../media/thumbnails/thumbnail-4.png"),
        require("../../media/thumbnails/thumbnail-5.png"),
      ],
      imgLoadStatus: [],
      imgsLoaded: false,
      displayProject: false,
      projectId: -1,
      projectName: "",
      projectDate: "",
      projectRoles: [],
      projectDescription: "",
      projectLanguage: "",
      projectTechnologies: "",
      projectUrl: "",
    };
    this.updateSortParams = this.updateSortParams.bind(this);
    this.updateFilterParams = this.updateFilterParams.bind(this);
    this.sortWorks = this.sortWorks.bind(this);
    this.getFilteredWorks = this.getFilteredWorks.bind(this);
    this.showProjectPage = this.showProjectPage.bind(this);
    this.hideProjectPage = this.hideProjectPage.bind(this);
    this.getProjectUrlButton = this.getProjectUrlButton.bind(this);
    this.imgLoaded = this.imgLoaded.bind(this);
    this.renderLoadSpinner = this.renderLoadSpinner.bind(this);
  }

  componentDidMount() {
    // Load in work/project entries
    let works = workData.map((project) => (
      <ProjectEntry
        project={project}
        thumbnail={this.state.thumbnails[project.id]}
        showProjectPage={this.showProjectPage}
        imgLoaded={this.imgLoaded}
      />
    ));
    this.allWorks = [...works];
    this.setState({ listedWorks: works }, () => {
      // Set initial sort to Recent work
      this.updateSortParams(SortWorkState.RECENT);
    });
  }

  updateSortParams(i) {
    // Don't update if user clicked the already toggled button
    if (this.state.sortParams[i]) return;

    this.setState(
      (prevState) => {
        const newSortParams = prevState.sortParams.map((param, j) => {
          if (j === i) {
            return !param;
          } else {
            return false;
          }
        });

        return {
          sortParams: newSortParams,
        };
      },
      () => {
        this.sortWorks();
      }
    );
  }

  updateFilterParams(i) {
    this.setState((prevState) => {
      const newFilterParams = prevState.filterParams.map((param, j) => {
        if (j === i) {
          return !param;
        } else {
          return false;
        }
      });

      return {
        filterParams: newFilterParams,
      };
    });
  }

  sortWorks() {
    if (this.state.sortParams[SortWorkState.RECENT]) {
      this.setState((prevState) => {
        let newListedWorks = prevState.listedWorks.sort((a, b) =>
          a.props.project.recencyRank > b.props.project.recencyRank ? 1 : -1
        );
        return { listedWorks: newListedWorks };
      });
    } else if (this.state.sortParams[SortWorkState.BEST]) {
      this.setState((prevState) => {
        let newListedWorks = prevState.listedWorks.sort((a, b) =>
          a.props.project.bestRank > b.props.project.bestRank ? 1 : -1
        );
        return { listedWorks: newListedWorks };
      });
    } else if (this.state.sortParams[SortWorkState.FAVORITE]) {
      this.setState((prevState) => {
        let newListedWorks = prevState.listedWorks.sort((a, b) =>
          a.props.project.favoriteRank > b.props.project.favoriteRank ? 1 : -1
        );
        return { listedWorks: newListedWorks };
      });
    } else {
      this.setState((prevState) => {
        const allWorksCopy = [...this.allWorks];
        return { listedWorks: allWorksCopy };
      });
    }
  }

  getFilteredWorks() {
    if (!this.state.filterParams.includes(true)) return this.state.listedWorks;

    let filteredString = "";
    if (this.state.filterParams[FilterWorkState.PROGRAMMER])
      filteredString = "Programmer";
    else if (this.state.filterParams[FilterWorkState.DESIGNER])
      filteredString = "Designer";

    let filteredWorks = [];
    this.state.listedWorks.forEach((element) => {
      if (element.props.project.roles.includes(filteredString))
        filteredWorks.push(element);
    });
    return filteredWorks;
  }

  showProjectPage(project) {
    if (project != null) {
      this.setState({
        displayProject: true,
        projectId: project.id,
        projectName: project.name,
        projectDate: project.date,
        projectRoles: project.roles,
        projectDescription: project.description,
        projectLanguage: project.language,
        projectTechnologies: project.technologies,
        projectUrl: project.url,
      });
    }
    // Prevent scrolling
    document.body.classList.add("prevent-scrolling");
  }

  hideProjectPage(e) {
    // Ignore clicks elsewhere
    if (e.target !== e.currentTarget) return;
    this.setState({
      displayProject: false,
    });
    // Resume scrolling
    document.body.classList.remove("prevent-scrolling");
  }

  getProjectUrlButton() {
    if (this.state.projectUrl != "") {
      return (
        <a className="btn btn-lg" href={this.state.projectUrl} target="_blank">
          Visit Project
        </a>
      );
    }
    return (
      <div>
        <p>Project Unavailable</p>
      </div>
    );
  }

  imgLoaded(i) {
    this.setState((prevState) => {
      // Set new ID as loaded
      let newStatus = [...prevState.imgLoadStatus];
      newStatus[i] = 1;

      // Check if all IDs are loaded
      let allLoaded = false;
      if (!newStatus.includes(0)) allLoaded = true;

      return { imgLoadStatus: newStatus, imgsLoaded: allLoaded };
    });
  }

  renderLoadSpinner() {
    if (!this.state.imgsLoaded)
      return <img className="loader-spinner" src={loadingSpinner} />;
    return null;
  }

  render() {
    // Get filter styles
    const btnSelected = " btn-selected";
    let recentBtnClass = this.state.sortParams[SortWorkState.RECENT]
      ? btnSelected
      : "";
    let bestBtnClass = this.state.sortParams[SortWorkState.BEST]
      ? btnSelected
      : "";
    let favoriteBtnClass = this.state.sortParams[SortWorkState.FAVORITE]
      ? btnSelected
      : "";
    let programmerBtnClass = this.state.filterParams[FilterWorkState.PROGRAMMER]
      ? btnSelected
      : "";
    let designerBtnClass = this.state.filterParams[FilterWorkState.DESIGNER]
      ? btnSelected
      : "";

    // Determine whether entries are ready show show
    let entriesDisplay = { display: "none" };
    if (this.state.imgsLoaded) entriesDisplay = {};

    return (
      <section className="Work page site-content">
        <div className="filter-container" id="work-filter">
          <div className="options-wrapper">
            <span>Sort by:</span>
            <a
              className={"btn btn-sm" + recentBtnClass}
              onClick={() => {
                this.updateSortParams(SortWorkState.RECENT);
              }}
            >
              Recent
            </a>
            <a
              className={"btn btn-sm" + bestBtnClass}
              onClick={() => {
                this.updateSortParams(SortWorkState.BEST);
              }}
            >
              Best
            </a>
            <a
              className={"btn btn-sm" + favoriteBtnClass}
              onClick={() => {
                this.updateSortParams(SortWorkState.FAVORITE);
              }}
            >
              Favorites
            </a>
          </div>
          <div className="options-wrapper">
            <span>Filter by:</span>
            <a
              className={"btn btn-sm" + programmerBtnClass}
              onClick={() => {
                this.updateFilterParams(FilterWorkState.PROGRAMMER);
              }}
            >
              Role: Programmer
            </a>
            <a
              className={"btn btn-sm" + designerBtnClass}
              onClick={() => {
                this.updateFilterParams(FilterWorkState.DESIGNER);
              }}
            >
              Role: Designer
            </a>
          </div>
        </div>
        {this.renderLoadSpinner()}
        <div className="entries-wrapper" style={entriesDisplay}>
          {this.getFilteredWorks()}
        </div>
        <div
          className="dark-display-screen"
          style={
            this.state.displayProject
              ? { display: "block" }
              : { display: "none" }
          }
          onClick={this.hideProjectPage}
        >
          <div className="card full-project-card">
            <i
              className="fas fa-times exit-btn"
              onClick={this.hideProjectPage}
            />
            <div className="full-project-content-container">
              <div className="full-project-content-col" id="col-left">
                <img
                  src={this.state.thumbnails[this.state.projectId]}
                  alt={this.state.projectName + " Project Thumbnail"}
                />
                {this.getProjectUrlButton()}
              </div>
              <div className="full-project-content-col" id="col-right">
                <h1>{this.state.projectName}</h1>
                <h2>
                  <i>{this.state.projectDate}</i>
                </h2>
                <p>{this.state.projectDescription}</p>
                <table>
                  <tr>
                    <th>Roles:</th>
                    <td>
                      {this.state.projectRoles.toString().replace(/,/gi, ", ")}
                    </td>
                  </tr>
                  <tr>
                    <th>Language:</th>
                    <td>{this.state.projectLanguage}</td>
                  </tr>
                  <tr>
                    <th>Technologies:</th>
                    <td>{this.state.projectTechnologies}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Work;
