import React from "react";
import "./ArtDisplay.css";

function ArtDisplay(props) {
  return (
    <div
      className="ArtDisplay"
      style={props.displayStatus[props.id] ? {} : { display: "none" }}
    >
      <img
        src={props.src}
        alt={props.alt}
        onLoad={() => {
          props.sourceLoaded(props.id);
        }}
        onError={() => {
          props.sourceLoaded(props.id);
        }}
      />
    </div>
  );
}

export default ArtDisplay;
