import React from "react";
import "./ProjectEntry.css";

class ProjectEntry extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
    this.renderBadges = this.renderBadges.bind(this);
  }

  handleClick() {
    // Call parent Work's function
    this.props.showProjectPage(this.props.project);
  }

  renderBadges() {
    return (
      <div>
        {this.props.project.roles.map((role) => {
          return <div className="badge">{role}</div>;
        })}
      </div>
    );
  }

  render() {
    return (
      <div className="ProjectEntry entry" onClick={this.handleClick}>
        <div className="card project-card">
          <img
            src={this.props.thumbnail}
            alt={this.props.project.name + " Project Entry Thumbnail"}
            onLoad={() => {
              this.props.imgLoaded(this.props.project.id);
            }}
            onError={() => {
              this.props.imgLoaded(this.props.project.id);
            }}
          />
          <h2>{this.props.project.name}</h2>
          <div className="project-card-sub-wrapper">
            <p>
              <i>{this.props.project.date}</i>
            </p>
            {this.renderBadges()}
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectEntry;
