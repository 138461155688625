import React from "react";
import "./NavBar.css";
import "../../utilities/common.css";
import { PageState } from "../../utilities/constants.ts";

class NavBar extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(pageState) {
    this.props.updatePageState(pageState);
  }

  render() {
    // Conditionally add selected class if button is selected
    const buttonClass = "nav-btn";
    const unselectedClass = "nav-btn-unselected";
    const underlineClass = "nav-btn-underline";
    let homeButtonClass = buttonClass;
    let homeUnderlineClass = "";
    let aboutButtonClass = buttonClass;
    let aboutUnderlineClass = "";
    let workButtonClass = buttonClass;
    let workUnderlineClass = "";
    let artButtonClass = buttonClass;
    let artUnderlineClass = "";

    switch (this.props.currPage) {
      case PageState.HOME:
        homeUnderlineClass = underlineClass;
        aboutButtonClass += " " + unselectedClass;
        workButtonClass += " " + unselectedClass;
        artButtonClass += " " + unselectedClass;
        break;
      case PageState.ABOUT:
        aboutUnderlineClass = underlineClass;
        homeButtonClass += " " + unselectedClass;
        workButtonClass += " " + unselectedClass;
        artButtonClass += " " + unselectedClass;
        break;
      case PageState.WORK:
        workUnderlineClass = underlineClass;
        homeButtonClass += " " + unselectedClass;
        aboutButtonClass += " " + unselectedClass;
        artButtonClass += " " + unselectedClass;
        break;
      case PageState.ART:
        artUnderlineClass = underlineClass;
        homeButtonClass += " " + unselectedClass;
        aboutButtonClass += " " + unselectedClass;
        workButtonClass += " " + unselectedClass;
        break;
    }

    return (
      <div className="NavBar">
        <div className="card card-bottom" id="nav-card">
          <div className="nav-btn-wrapper">
            <div
              className={homeButtonClass}
              onClick={() => {
                this.handleClick(PageState.HOME);
              }}
            >
              Home
            </div>
            <div className={homeUnderlineClass} />
          </div>
          <div className="nav-btn-wrapper">
            <div
              className={aboutButtonClass}
              onClick={() => {
                this.handleClick(PageState.ABOUT);
              }}
            >
              About Me
            </div>
            <div className={aboutUnderlineClass} />
          </div>
          <div className="nav-btn-wrapper">
            <div
              className={workButtonClass}
              onClick={() => {
                this.handleClick(PageState.WORK);
              }}
            >
              Work
            </div>
            <div className={workUnderlineClass} />
          </div>
          <div className="nav-btn-wrapper">
            <div
              className={artButtonClass}
              onClick={() => {
                this.handleClick(PageState.ART);
              }}
            >
              Art
            </div>
            <div className={artUnderlineClass} />
          </div>
        </div>
      </div>
    );
  }
}

export default NavBar;
