import React from "react";
import NavBar from "../NavBar/NavBar";
import SocialBar from "../SocialBar/SocialBar";
import Home from "../Home/Home";
import About from "../About/About";
import Work from "../Work/Work";
import Art from "../Art/Art";
import { PageState } from "../../utilities/constants.ts";
import "./App.css";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      currPage: PageState.HOME,
    };
    this.updatePageState = this.updatePageState.bind(this);
    this.getPage = this.getPage.bind(this);
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://kit.fontawesome.com/0e6b9a1606.js";
    script.crossorigin = "anonymous";
    document.head.appendChild(script);
  }

  updatePageState(newPageState) {
    window.scrollTo(0, 0);
    this.setState({ currPage: newPageState });
  }

  getPage() {
    switch (this.state.currPage) {
      case PageState.HOME:
        return <Home />;
      case PageState.ABOUT:
        return <About />;
      case PageState.WORK:
        return <Work />;
      case PageState.ART:
        return <Art />;
      default:
        return null;
    }
  }

  render() {
    document.title = "Ethan DeFrank";

    return (
      <div className="App">
        <NavBar
          currPage={this.state.currPage}
          updatePageState={this.updatePageState}
        />
        {this.getPage()}
        <SocialBar />
      </div>
    );
  }
}

export default App;
