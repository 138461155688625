import React from "react";
import "./ArtEntry.css";

class ArtEntry extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    // Call parent Art's function
    this.props.displayArt(this.props.art);
  }

  render() {
    return (
      <div className="ArtEntry entry" onClick={this.handleClick}>
        <img
          src={this.props.thumbnail}
          alt={this.props.art.title + " Art Preview"}
          onLoad={() => {
            this.props.thumbLoaded(this.props.art.id);
          }}
          onError={() => {
            this.props.thumbLoaded(this.props.art.id);
          }}
        />
      </div>
    );
  }
}

export default ArtEntry;
