import React from "react";
import "./SocialBar.css";
import "../../utilities/common.css";

class SocialBar extends React.Component {
  constructor() {
    super();
    this.state = {
      linkedin: "https://www.linkedin.com/in/ethandefrank/",
      dribbble: "https://dribbble.com/ethandefrank",
      codepen: "https://codepen.io/discocatphd",
      twitter: "https://twitter.com/discocatphd",
      instagram: "https://www.instagram.com/discocatphd/",
    };
  }
  render() {
    return (
      <div className="SocialBar">
        <div className="card card-top" id="socials-card">
          <div className="socials-wrapper">
            <a href={this.state.linkedin} target="_blank">
              <i className="fab fa-linkedin" />
            </a>
            <a href={this.state.dribbble} target="_blank">
              <i className="fab fa-dribbble" />
            </a>
            <a href={this.state.codepen} target="_blank">
              <i className="fab fa-codepen" />
            </a>
            <a href={this.state.twitter} target="_blank">
              <i className="fab fa-twitter" />
            </a>
            <a href={this.state.instagram} target="_blank">
              <i className="fab fa-instagram" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default SocialBar;
