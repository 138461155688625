import React from "react";
import "./Home.css";
import "../../utilities/common.css";
import heroMedia from "../../media/hero-pixel-art.png";
import resume from "../../media/2020-DeFrank-Resume.pdf";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      location: "Pittsburgh, PA",
      email: "ethandefrank@gmail.com",
    };
  }

  render() {
    return (
      <section className="Home site-content">
        <div className="card" id="intro-card">
          <h1 className="hero-title">
            Ethan DeFrank
            <div className="badge">Portfolio</div>
          </h1>
          <div className="roles-subtitle">
            <h2>Programmer</h2>
            <h2>Designer</h2>
            <h2>Artist</h2>
          </div>
          <table className="personal-details">
            <tr>
              <th>
                <i class="fas fa-map-marker-alt" />
              </th>
              <td>{this.state.location}</td>
            </tr>
            <tr>
              <th>
                <i class="fas fa-envelope" />
              </th>
              <td>
                <a href={"mailto:" + this.state.email}>{this.state.email}</a>
              </td>
            </tr>
          </table>
          <p className="welcome-text">
            Welcome! This is where I showcase my work on software projects as
            well as my art. If you'd like to learn more about me or my work,
            click the navigation links above. Thanks for stopping by!
          </p>
          <a className="btn btn-lg" href={resume} target="_blank">
            Download Resume
          </a>
        </div>
        <div className="card" id="hero-media-card">
          <img src={heroMedia} alt="Ethan's Avatar" />
        </div>
      </section>
    );
  }
}

export default Home;
